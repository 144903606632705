.boutique-title {
    margin-top: 1rem;

    h1 {
        display: none;
    }

    img {
        display: block;
        height: 85px;
        margin: 10px auto;
        padding-top: 10px;

        @include xssm {
            height: 50px;
        }
    }
}

.cm-title {
    margin-left: 15px;
}

.cm-main-title {
    display: none;

    h1 {
        &:after {
            background: $color_brand_primary;
        }
    }

    .container {
        @include md {
            width: auto;
        }
    }
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mdlg {
        display: grid;
        grid-template-areas: '. head-left logo head-right .';
        grid-template-columns: auto minmax(0, 292px) minmax(0, 585px) minmax(0, 292px) auto;
    }

    .navbar-brand {
        &::before {
            color: $gray;
            content: '< Retour vers le site';
            font-size: 12px;
            margin-top: -5px;
            position: absolute;
            width: 130px;

            @include mdlg {
                display: none;
            }            
        }
        svg {
            display: none;
            @include mdlg {
                display: block;
            }
        }
    }

    &.navbar-inverse {
        background: $white;
        border-bottom: 1px solid $gray_light;
        color: $gray_darker;
    }
}

.region-navigation {
    flex: 0 0 100%;
}

section:has(.cm-call){
    height: 100%;
}

#skip-link {
display: none;
}

.cm-call {
    align-self: flex-end;
    align-items: center;
    display: none;
    margin-bottom: 30px;

    @include mdlg {
        display: flex;
        justify-content: flex-end;
        margin-top: calc(50% - 33%);
    }

    span {
        font-family: $font-text-bold;
        font-size: 14px;
        line-height: 1.5;
        text-align: right;
    }

    a {
        color: $color_link;
        text-decoration: underline;
    }

    .cm-call__img {
        height: auto;
        margin: 0 12px;
        width: 40px;
    }
}
.sso-non-connecte {
    background: white;
    border-bottom: 1px solid white;
    .sso-user-menu {
        border-left: 0px solid #797979;
    }
    .sso-menu-item {
        &:hover {
            background: #797979;
        }
    }      
}

.sso-user-menu {
    color: $color_brand_primary;

    &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: $color_brand_primary transparent transparent transparent;
    position: absolute;
    right: 10px;
    top: 15px;
    }

    a, 
    a:hover, 
    a:focus {
        color: white;      
    }
}

.sso-logo {

    &::before {
        color: gray;
        content: '< Retour vers le site';
        font-size: 12px;
        margin-top: -5px;
        position: absolute;
        width: 130px;
    
        @include mdlg {
            display: none;
        }     
    }  
    
    img {
        height: 23px;
        margin: 3px 0;
        display: none;

        @include mdlg {
            display: block;
        }
    }
}

.sso-user-menu,
.sso-non-connecte .sso-user-menu {
    color: $color_brand_primary;
    width: 60px;
    background: url('/sites/all/themes/custom/boutique/bootstrapMarketingSoirmag/img/user.svg') center left 5px no-repeat !important;
    background-size: auto;
    background-size: auto 50% !important;
    text-indent: -999em;
    padding: 0;
    border-left: 0px solid #797979;

    &:hover {
        background: url('/sites/all/themes/custom/boutique/bootstrapMarketingSoirmag/img/user.svg') center left 5px no-repeat !important;
        background-size: auto 50% !important;
    }  
   
    &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: $color_brand_primary transparent transparent transparent;
        position: absolute;
        right: 10px;
        top: 15px;
    }    
}
