.btn {
    background: $color_brand_primary;

    a {
        color: $white;
        font-weight: bold;

        .view-argumentaires & {
            font-weight: normal;
        }
    }
    &:not(.swg-button):hover {
        color: white;
    }
}

.btn_more_abo {
    button {
        background: $color_brand_primary;
        color: $white;
        font-family: $font-title;

        &:hover {
            background: darken($color_brand_primary, 10%);
        }
    }
}
