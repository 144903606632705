.node-type-formulaire-perso,
.node-type-webform {
    .price {
        display: none;
    }
}

.cm-form-left {
    padding-top: 0;
    position: relative;
}

.form-actions {
    .webform-submit,
    .webform-next {
        &.btn {
            color: $white;
            font-weight: normal;
        }
    }
}

.price {
    min-height: 150px;
}

.field-name-field-descriptif-long {
    margin: auto;
    max-width: $max-width-xs;
}

// barre d'étapes
.cm-progressbar {
    &:first-child {
        .cm-progressbar-page {
            &.current {
                background: $black;
                color: $white;
            }
        }
    }
}

.cm-progressbar-page {
    font-weight: normal;

    &.current {
        color: $black;
    }
}


