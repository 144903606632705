// --- Color ---
$color_brand_primary: #e12a36;
$color_brand_secondary: #000;

$color_link: #e12a36;

// --- Fonts ---
$font-nav:  oswald, arial, sans-serif;
$font-text:  'ralewaylight', arial, sans-serif;
$font-text-semibold:  'ralewayregular', arial, sans-serif;
$font-text-bold:  'ralewaysemibold', arial, sans-serif;
//$font-title:  'bebas_neuebold', arial, sans-serif;
