#cm-menu-formule {
    ul {
        float: none;
        text-align: center;

        li {
            float: none;

            a {
                color: #1f1c1c;
            }
        }
    }
}
