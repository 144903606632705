//global
.node-type-kiosque {
    .cm-main-title {
        h1 {
            margin-bottom: 0;
        }
    }

    .field-name-field-visuel-grand {
        width: 100%;

        .img-responsive {
            width: 100%;
        }
    }

    .field-name-field-prix-affiche {
        background: $color_brand_primary;
        color: $white;
        font-size: 38px;
        height: 120px;
        line-height: 120px;
        right: -15px;
        width: 120px;
    }

    .cm-form-left {
        border: 0;
        padding: 0;
    }

    .onglets-paiement {
        .panel-heading {
            display: none;
        }

        .panel-body {
            padding-top: 0;

            .tab-pane {
                border-bottom: 5px solid $gray_light;
                display: block !important;
                margin-bottom: 30px;
                padding-bottom: 30px;

                &:last-child {
                    border: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
