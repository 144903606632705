.cm-thank {

  .cm-thank-msg {
    p {
        margin: 0 0 20px;
    }
  } 

  .cm-sepa-btn {
    background-color:#e12a36;
    border-radius:30px;
    height: 50px;
    padding:10px 20px;
    text-decoration: none;
    width: auto;

    span {
        color: #fff;
        font-size:16px;
        font-style:normal;
        font-weight: bold;
    }

    p:has(a) {
      margin:20px 0px; 
    }
  }
}
