.box_call_center {
    right: 30px;
    top: 5px;

    @include md {
        right: 30px;
    }

    @include xssm {
        top: 52px;
    }

    .cm-box {
        box-shadow: 0 3px 7px $gray-light;
        color: $black;

        @include xssm {
            background: $white;
        }
    }

    .cm-call {
        background: $color_brand_primary;
        height: 30px;
        width: 30px;

        &:before {
            background: $color_brand_primary;
            color: $white;
            content: '\E00F';
            font-family: icons;
            font-size: 14px;
            line-height: 30px;
            padding: 8px;
        }
    }
}

.call_center {
    min-width: 360px;

    .node-type-formulaire-perso &,
    .node-type-webform & {
        a {
            color: $color_brand_primary;
        }
    }
}
