.form-checkboxes {
    .checkbox {
        .control-label {
            font-size: $font-size-base;
        }
    }
}

.checkbox {
    .checkbox-material {
        top: 0;

        .check {
            height: 16px;
            width: 16px;

            &:before {
                margin-left: 5px;
                margin-top: -6px;
            }
        }
    }
}

.radio {
    label {
        span {
            top: 5px;
        }
    }
}

.webform-client-form {
    .webform-component-radios,
    .webform-component-checkboxes {
        margin: 0;

        .control-label {
            margin: 0;
        }
    }
}

.cm-adresse-belge {
    &.panel-default {
        .panel-heading {
            span {
                color: $black;
            }
        }

        .panel-body {
            padding-top: 15px;
        }
    }
}

.cm-date {
    margin-left: 0;
    margin-right: 0;
}
