//blocs offres
.wrap-offre {
    .box-offre {
        &:hover {
            @include mdlg {
                background-color: $color_brand_secondary;
                background-image: linear-gradient(235deg, rgba($white, .5) 0%, rgba($white, 0) 70%);

                h2 {
                    color: $white;
                }

                a {
                    color: $white;
                }

                .img-responsive {
                    box-shadow: none;
                }

                .price {
                    background: none;
                    color: $white;

                    span {
                        color: $white;
                    }
                }
            }
        }

        &.premium {
            background-color: $color_brand_primary;
            background-image: linear-gradient(235deg, rgba($white, 0) 0%, rgba($white, 0) 70%);

            &:hover {
                @include mdlg {
                    background-color: $color_brand_secondary;
                    background-image: linear-gradient(235deg, rgba($white, .5) 0%, rgba($white, 0) 70%);
                    position: relative;

                    a {
                        color: $white;
                    }

                    h2 {
                        color: $white;
                    }

                    .img-responsive {
                        box-shadow: none;
                    }

                    .price {
                        background: none;
                        color: $white;
                    }
                }
            }

            .price {
                background: none;
                color: $white;
            }
        }
    }
}

.surtitre {
    color: $white;
    display: none;
}

// Affichage du prix
.price {
    background: none;
    font-size: 2.5rem;
    line-height: 3rem;

    strong {
        font-size: 5.5rem;
        line-height: 5.5rem;

        span {
            font-size: 3rem;
            line-height: 3rem;
        }
    }
}