#logo {
    svg {
        height: 24px;
    }
}

.r--toolbar_sso {
    r-box--body {
        padding: 0;
    }
}

.r-sso {
    background-color: white;
    //box-shadow: 0 0 2px #575757;
    color: $color_brand_secondary;
    display: flex;
    font-family: $font-family-base;
    font-size: 16px;
    height: 36px;
    left:0;
    line-height: 26px;
    padding: 5px 10px;
    position: fixed;
    top:0;    
    right:0;

    a {
        color: $white;
        line-height: 24px;
    }

    .navbar-brand {
        padding: 0;
    }
}

.r-sso-logo {
    margin-right: auto;
}

.r-sso-user-menu {
    margin-left: auto;
    padding:0 1rem;
    position: relative;
    
    label {
        color: $color_brand_primary;
        cursor: pointer;
        display: flex;
        
        span {
            font-weight: normal;
            margin-left: 10px;
            color: color(dark);

            @include xssm {
                display: none;
            }
        }
    }
}

.r-sso-menu {
    background: $white;
    box-shadow: 2px 2px 2px #ccc;
    color: #000;
    display: none;
    list-style: none;
    margin: 0;
    min-width: 180px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 31px;
    width: auto;
    z-index: 11;

    @include xssm {
        right: 0;
    }
}

.r-sso-menu li {
    background: #f5f5f5;
    margin-bottom: 1px;
}

.r-sso-menu a {
    background: #f5f5f5;
    color: #575757;
    display: block;
    text-decoration: none;
    padding: 10px 15px;
}

.r-sso-menu a:hover {
    background: #ededed;
    color: #575757;
    text-decoration: none;
}

.r-sso-checkbox {
    display:none;
}

.r-sso-checkbox:checked ~ .r-sso-menu {
    display: block
}

.r-sso-close {
    display: none;
}

.r-sso-checkbox:checked ~ label .r-sso-close {
    display: block;
}

.r-sso-checkbox:checked ~ label .r-sso-open {
    display: none;
}

.r-sso-menu .r-sso-compte {
    background: $white;
    color: $color_brand_primary;
    padding: 15px;
}

.r-sso-menu .r-sso-compte strong {
    color: #242424;
}
