.onglets-paiement,
.message-renew-card {
    .panel-heading {
        color: $black !important;

        a {
            color: $black !important;
            line-height: 31px;

            &:hover {
                color: $color_brand_primary !important;
            }
        }

        .nav-tabs {
            background: $white;
        }

        .active {
            background-color: $gray_lighter;
        }

        fieldset.panel-default & {
            span {
                font-size: inherit;
            }
        }
    }

    .panel {
        border-radius: 0;
        box-shadow: none;
    }

    iframe {
        max-width: 100%;
    }

    .webform-component-textfield {
        position: relative;

        &>img,
        &>svg {
            width: 40px;
            position: absolute;
            bottom: 14px;
            right: 16px;
            z-index: 2222222;
        }

        label.control-label {
            margin-top: 4px;
            position: relative !important;
            top: auto !important;
        }

        &.validDate {
            display: inline-block;
            margin-right: 11px;
            width: calc(100% - 140px);

            &>div {
                border-bottom: 1px solid lighten(#BDBDBD, 10%);
                border-radius: 0;
                padding: 0 6px;
            }


            @include smmdlg {
                width: calc(50% - 10px);

            }

            &.monthyearInvalid {
                &>div {
                    border-color: red;
                }
            }

            &.monthyearValid {
                &>div {
                    border-color: green;
                }
            }


            &+.webform-component-textfield {
                display: inline-block;
                margin-right: 0;
                width: 120px;

                @include smmdlg {
                    width: calc(50% - 8px);
                }
            }

            &>label {
                margin: 0;
                width: 100%;
            }



            div {
                background-clip: padding-box;
                background-color: $white;
                //border: 1px solid color(primary);
                //border-radius: 10px;
                color: color(gray, dark);
                display: block;
                line-height: 1.5;
                margin: 0;
                padding: 6px 0;
                width: 100%;

                select {
                    display: inline-block;
                    border: none;
                    background: none;
                }

                label {
                    display: inline-block;
                    margin: 0;
                }
            }
        }

        &.monthyearInvalid>div {
            &>div {

                border-bottom: 1px solid red;
            }
        }

        &.monthyearValid>div {
            &>div {
                border-bottom: 1px solid green;
            }
        }

        >div {
            background-clip: padding-box;
            background-color: $white;
            border-bottom: 1px solid lighten(#BDBDBD, 10%);
            border-radius: 0;
            color: black;


            select {
                display: inline-block;
                border: none;
                background: none;
            }

            label {
                display: inline-block;
                margin: 0;
            }

            &>svg,
            &>img {
                width: 40px;
                position: absolute;
                bottom: space(.5);
                right: space();
                z-index: 2222222;
            }
        }
    }

    .validNumber {
        .creditCardVisa {
            opacity: 0;
        }

        .creditCardMaster {
            opacity: 0
        }

        .ccInvalid {
            &~.creditCard {
                opacity: 1 !important;
            }

            &~.creditCardVisa {
                opacity: 0 !important;
            }

            &~.creditCardMaster {
                opacity: 0 !important;
            }

            &.ccMastercard {
                &~.creditCardMaster {
                    opacity: .4 !important;
                }

                &~.creditCardVisa,
                &~.creditCard {
                    opacity: 0 !important;
                }
            }

            &.ccVisa {
                &~.creditCardVisa {
                    opacity: .4 !important;
                }

                &~.creditCardMaster,
                &~.creditCard {
                    opacity: 0 !important;
                }
            }
        }

        .ccVisa {
            &~.creditCardVisa {
                opacity: 1;
            }

            &.ccInvalid {
                &~.creditCardVisa {
                    opacity: .4;
                }
            }

            &~.creditCard,
            &~.ccMastercard {
                opacity: 0;
            }
        }

        .ccMastercard {
            &~.creditCardMaster {
                opacity: 1;
            }

            &.ccInvalid {
                &~.creditCardMaster {
                    opacity: .4;
                }
            }

            &~.creditCard {
                opacity: 0;
            }
        }
    }

    .form-text,
    .form-control,
    .form-group .form-control {
        background: none;
        background-color: $white;
        border-radius: 0;
        border-bottom: 1px solid lighten(#BDBDBD, 10%);


        &.ccInvalid,
        &.cvcInvalid,
        &.cardholderInvalid {
            border-color: red;
        }

        &.ccValid,
        &.cvcValid,
        &.cardholderValid {
            border-color: green;
        }

    }


    #tab-payment-OGU,
    #tab-payment-OG,
    #tab-payment-OGR_FREE,
    #tab-payment-OGR {
        .validDate {
            display: inline-block;
            margin-right: 11px;
            width: calc(100% - 120px);

            @include smmdlg {
                width: calc(50% - 8px);
            }

            &.monthyearInvalid {
                &>div {
                    border-color: red;
                }
            }

            &.monthyearValid {
                &>div {
                    border-color: green;
                }
            }


            &+.webform-component-textfield {
                display: inline-block;
                margin-right: 0;
                width: 104px;

                @include smmdlg {
                    width: calc(50% - 8px);
                }
            }

            &>label {
                margin: 0;
                width: 100%;
            }



            div {

                select {
                    display: inline-block;
                    border: none;
                    background: none;
                }

                label {
                    display: inline-block;
                    margin: 0;
                }
            }
        }

    }
}

.message-renew-card {
    max-width: 640px;
    margin: auto;
}