.middle-top {
    @include flex;
    text-align: center;

    .panel-pane {
        margin: auto;
    }
}

.main-container {
    margin-top: 20px;
    .main-title {
        font-family: $font-title;
        font-size: 24px;
        font-weight: bold;
        padding: 1rem;
        text-align: center;
        @include mdlg {
            font-size: 36px;
        }
    }
}
