input {
    font-family: $font-text-bold;
}

//fix background jaune sur chrome
input,
textarea,
select {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #fbfbfb inset !important;
    }
}
