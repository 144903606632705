.gr-footer-menu {
    background: $black;
    color: $white;
    padding: 15px 0;
    font-size: 15px;

    a {
        color: $white;
    }

    p {
        margin: 0;
    }
}


footer {
    background: color(gray, lighter);
    padding: 5px;

    @include smmdlg {
        // align-items: center;
        // display: flex;
        // justify-content: space-between;
    }

    .r--menu-menu-footer-le-soir {
        margin: 0;
        display: block;
        
        @include mdlg {
            align-items: center;
            display: flex;
            
        }

        .r-menu--link {
            cursor: pointer;
            font-weight: 300;
            padding: space(.25) space(.5);
            
        }
    }
}
