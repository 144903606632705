@font-face {
    font-family: 'ralewaylight';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-light-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-light-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-light-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-light-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-light-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-light-webfont.svg#ralewaylight') format('svg');
}

@font-face {
    font-family: 'ralewayregular';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.svg#ralewayregular') format('svg');
}

@font-face {
    font-family: 'ralewaysemibold';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.svg#ralewaysemibold') format('svg');
}

@font-face {
    font-family: 'economica';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/economica/Economica-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-regular-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-regular-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-regular-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-regular-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-regular-webfont.svg#oswaldregular') format('svg');
}

@font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: lighter;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-light-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-light-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-light-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-light-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-light-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-light-webfont.svg#oswaldbold') format('svg');
}

@font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: bold;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.svg#oswaldbold') format('svg');
}
