//fonts
@import 'projet/fonts';

// ######## Import Bootstrap ########
@import '../bootstrapMarketing/bootstrap';

// ######## project ########
// variable core
@import '../bootstrapMarketing/projet/variable';

//variable theme Soirmag
@import 'projet/variable';

//mixins
@import '../bootstrapMarketing/projet/mixin';

//core
@import '../bootstrapMarketing/core';


// ######## surcharge ########

//projet
@import 'projet/base';

//layout
@import 'layout/structure';
@import 'layout/kiosque';
@import 'layout/thankyou';
@import 'layout/header';
@import 'layout/footer';
//
//module
@import 'module/menu_main';
@import 'module/menu_formule';
@import 'module/box_offre';
@import 'module/page_slider';
@import 'module/call_center';
@import 'module/btn';
@import 'module/carousel';
@import 'module/forms';
@import 'module/payment';
@import 'module/fields';
@import 'module/sso';