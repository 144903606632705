#cm-main-menu {
    ul {
        float: none;
    }

    li {
        float: none;
    }

    a {
        line-height: 40px;
        padding: 0 15px;
    }
}
