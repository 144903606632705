// put toggle bars on the left :: not using button

#slide-nav {
    .navbar-toggle {
        background: transparent;
    }
}

@include xssm {
    #slide-nav {
        #slidemenu {
            background: #f7f7f7;
        }

        #navbar-height-col {
            background: #eee;
        }

        &.navbar-inverse {
            #slidemenu {
                background: #333;
            }

            #navbar-height-col {
                background: #333;
            }
        }
    }

    #slidemenu {
        .nav {
            .active {
                background-color: $color_brand_primary;

                a {
                    color: $white;
                }
            }
        }
    }
}
